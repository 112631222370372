import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { P, SectionHeading, SectionWrapper } from "../../../styles";

const LogoImage = styled.div`
  padding: 100px 0 0 0;
  @media screen and (max-width: 899px) {
  padding: 10px 0;
}
`;
const DogImage = styled(Grid)`
  display: flex;
  align-items: center;
  @media screen and (max-width: 899px) {
    place-content: center;
  }
`;
const Infiled = styled.div`
  padding: 0 0 60px 0;
  position: relative;
`;
const Heading = styled.div`
  position: absolute;
  top: -30px;
  left: 20px;
  font-size: 35px;
  font-weight: 900;
  font-family: "poppins-Bold";
`;
const TBA = styled.div`
  max-width: 70%;
  border-radius: 20px;
  border: 2px solid #6a5b9f;
  padding: 25px 20px;
  box-shadow: inset 1px 0px 10px 1px rgb(0 0 0 / 50%);
  @media screen and (max-width: 899px) {
    max-width: 100%;
  }
`;
const Contract = styled.div`
  color: #f7c01a;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0 60px 0;
`;
const TbaSPan = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: 200;
  padding-left: 10px;
`;
const PoodleImage = styled.img`
  width:100%;
  height: auto;
  max-width: 530px;
`
const PoodleLogoImage = styled.img`
  width:100%;
  height: auto;
  max-width: 290px;
`

const index = () => {
  return (
    <div>
      <SectionWrapper p="10rem 0" id="tokenomics">
        <Container maxWidth="xl">
          <Grid container>
            <DogImage
              xs={12}
              md={6}
              data-aos="fade-up"
              data-aos-duration="19000"
            >
              <PoodleImage
                src="/images/poodletokenomics.png"
              />
            </DogImage>
            <Grid xs={12} md={6}>
              <SectionHeading
                p899="40px 0"
                fs="50px"
              mt= "0px"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="100"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                Tokenomics
              </SectionHeading>
              <LogoImage
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="300"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                <PoodleLogoImage
                  src="/images/logo.png"
                />
                <P
                  maxWidth="70%"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="600"
                  data-aos-offset="0"
                  data-aos-duration="1000"
                >
                  {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et. */}
                </P>
              </LogoImage>
              <Contract
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                Contract:<TbaSPan>contractTBA</TbaSPan>
              </Contract>
              <Infiled data-aos="fade-up" data-aos-duration="1900">
                <Heading>SUPPLY</Heading>
                <TBA>100 Million</TBA>
              </Infiled>

              {/* <Infiled data-aos="fade-up" data-aos-duration="1900">
                <Heading>Max Buy</Heading>
                <TBA>1%</TBA>
              </Infiled>
              <Infiled data-aos="fade-up" data-aos-duration="1900">
                <Heading>Max Wallet</Heading>
                <TBA>2%</TBA>
              </Infiled> */}
              <Infiled data-aos="fade-up" data-aos-duration="1900">
                <Heading>Taxes</Heading>
                <TBA>4/4</TBA>
              </Infiled>
            </Grid> 
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;

import React, { useEffect } from "react";

import {
  P,
  SectionHeading,
  SectionSmallHeading,
  SectionSubHeading,
} from "../../../styles";
import Menu from "./menu";
import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";

import Aos from "aos";
import "aos/dist/aos.css";
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 120px 0 50px 0;
`;
const SectionBackground = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  background: url("/images/topbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  @media screen and (max-width: 899px) {
    padding: 10px 0 0 0;
  }
`;
const ReadMore = styled.a`
  background-color: #fff;
  cursor: pointer;
  background: linear-gradient(to top, #d9640d, #fccb00);
  border-radius: 10px;
  padding: 10px 40px 10px 40px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  box-shadow: ${(props) =>
    props.bs
      ? props.bs
      : "inset 0px 2px 5px 0px #fed0ae, 0px 6px 18px 0px #000"};
  &:hover {
    opacity: 0.8;
  }
`;
const Title = styled.span`
  color: #fccb00;
  font-weight: 600;
  font-size: 18px;
`;
const Intro = styled(Grid)`
  padding: 50px 0 0 0;
  display: flex;
  align-items: end;

  /* place-content: center; */
  /* place-content: end; */
`;
const PoodleImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 450px;
`;

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  const Span = styled.span``;
  return (
    <div>
      <Menu />
      <SectionBackground>
        <SectionWrapper>
          <Container maxWidth="xl">
            <SectionSmallHeading
              align="center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              data-aos-delay="100"
              data-aos-offset="0"
              data-aos-duration="1000"
            >
              Welcome To Poodle Coin
            </SectionSmallHeading>
            <SectionHeading
              align="center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              data-aos-delay="300"
              data-aos-offset="0"
              data-aos-duration="1000"
            >
              Let The Fun Begin
            </SectionHeading>
            <PoodleImage
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-offset="0"
              data-aos-duration="1000"
              src="/images/poodle.png"
            />

            <P
              align="center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              data-aos-delay="800"
              data-aos-offset="0"
              data-aos-duration="1000"
            >
              The first and only token that yields actual stablecoin LP and
              constantly burns token <br />
              supply to pump your investment to the next dimension!
            </P>
            {/* <ReadMore>Read More</ReadMore> */}
          </Container>
        </SectionWrapper>
      </SectionBackground>
    </div>
  );
};

export default Index;

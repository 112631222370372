import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionHeading,
  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";
const FeatursCard = styled(Grid)`
  align-items: center;
  text-align: center;
`;

const Box = styled.div`
  text-align: ${(props) => (props.align ? props.align : "")};
  background: rgba(
    255,
    255,
    255,
    0.3
  ); /* CSS Colors 4 space-separated values */
  background-image: radial-gradient(#532367, #2c2263);
  box-shadow: inset 1px 1px 13px 0px rgba(255, 255, 255, 0.5);

  text-align: -webkit-center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  padding: ${(props) => (props.padding ? props.padding : "30px")};
  background: radial-gradient(2px 20px #fff);
  /* max-width: ${(props) => (props.maxWidth ? props.maxWidth : "10%")}; */
  padding-left: ${(props) => (props.pl ? props.pl : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "40px")};
  max-width: 80%;

  @media screen and (max-width: 899px) {
    padding-left: ${(props) => (props.pl899px ? props.pl899px : "10px")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.padding599 ? props.padding599 : "")};
    max-width: 100%;
  }
`;
const RedImage = styled.img`
width: 100%;
height: auto;
max-width: 122px
`
const BlueImage = styled.img`
width: 100%;
height: auto;
max-width: 122px
`

const index = () => {
  return (
    <div>
      <SectionWrapper
        id="features"
        data-aos="fade-up"
        data-aos-duration="19000"
        pt ="0px"
      >
        <Container maxWidth="xl">
          {/* <SectionHeading p="50px 0" align="center">
            Features
          </SectionHeading> */}
          <Grid container spacing={7}>
            <Grid item sx={12} md={6}>
              <Box
                pl899px="40px"
                minh="280px"
                maxWidth="90%"
                padding="50px"
                pl="0px"
                padding599="20px"
              >
                <FeatursCard container>
                  <Grid item xs={12} md={5}>
                    <BlueImage
                      src="/images/bluemoon.png"
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <SectionSubHeading tc="left"  mb="0px">
                      Auto liquidty
                    </SectionSubHeading>
                    <P mt="16px" mb="16px">
                      Our contract automatically collects and deposits tokens
                      straight into the liquidity pool from each transaction.
                    </P>
                  </Grid>
                </FeatursCard>
              </Box>
            </Grid>
            <Grid item sx={12} md={6}>
              <Box
                padding599="20px"
                pl899px="40px"
                minh="280px"
                padding="50px"
                pl="0px"
                maxWidth="90%"
              >
                <FeatursCard container>
                  <Grid xs={12} md={5}>
                    <RedImage
                      src="/images/redmoon.png"
                    />
                  </Grid>
                  <Grid xs={12} md={7}>
                    <SectionSubHeading tc="left" mb="0px">
                      Auto Burning
                    </SectionSubHeading>
                    <P mt="16px" mb="16px">
                      For every transaction, POP buys back some of its tokens
                      and burns them out of the circulating supply forever.
                    </P>
                  </Grid>
                </FeatursCard>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;

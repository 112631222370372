import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import DesktopMenu from "./deskTopMenu";
import MobileMenu from "./mobileMenu";
import { MenuContainer } from "./styles";

const MenuList = [
  ["Media Kit", "/files/mediakit.zip"],
  ["Problem", "/#about"],
  ["Solution", "/#solution"],
  ["Tokenomics", "/#tokenomics"],
  [
    "Buy Now",
    "https://app.uniswap.org/#/swap?outputCurrency=contractTBA&chain=mainnet",
  ],
  [
    "View Chart",
    "https://www.dextools.io/app/ether/pair-explorer/viewchart",
  ],
  ["Join Telegram", "https:/t.me/poodle_avax"],
];

const checkSticky = "";
const Menu = () => {
  // const [useCheckSticky, setCheckSticky] = useState("no-sticky");
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });
  // const isSticky = (e) => {
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 20 ? setCheckSticky("sticky") : setCheckSticky("No-sticky");
  // };
  return (
    <MenuContainer
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in"
      data-aos-delay="300"
      data-aos-offset="0"
      data-aos-duration="1000"
    >
      <DesktopMenu menuList={MenuList} />
      <MobileMenu menuList={MenuList} />
    </MenuContainer>
  );
};

export default Menu;

import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionHeading,
  SectionSmallHeading,
  SectionWrapper,
} from "../../../styles/index";

const GridImage = styled(Grid)`
  text-align-last: center;
  position: relative;
  @media screen and (max-width: 899px) {
    padding: 90px 0;
  }
`;

const MovingImg = styled.div`
  width: 50%;
  float: right;
  padding-right: 220px;
  display: block;
  margin: 0px auto 0px;
  // height: 300px;
  // width: auto;
  animation: 3s infinite alternate floating;
  @media (max-width: 1199px) {
    width: 70%;
    float: none;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  @media (max-width: 599px) {
    /* width: 54%; */
  }
  @media (max-width: 425px) {
    /* width: 74%; */
  }
  @-webkit-keyframes floating {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;
const ShadowImg = styled.div`
  position: absolute;
  opacity: 0.7;
  bottom: -50px;
  left: 180px;
  height: 2px;
  display: block;
  margin: 15px auto;
  animation: 3s infinite alternate shadow;
  @-webkit-keyframes shadow {
    0% {
      width: 180px;
      box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.8);
    }
    50% {
      width: 150px;
      box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.5);
    }
    100% {
      width: 180px;
      box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.8);
    }
  }
  @media screen and (min-width: 1400px) {
    bottom: -50px;
    left: 240px;
  }
  @media (max-width: 1220px) {
    bottom: 30px;
    left: 180px;
  }
  @media (max-width: 1000px) {
    bottom: 100px;
    left: 140px;
  }
  @media (max-width: 899px) {
    bottom: 40px;
    right: 140px;
  }
  @media (max-width: 500px) {
    bottom: 40px;
    left: 60px;
  }
`;
const ProblemImage = styled.img`
width: 100%;
height: auto;
max-width: 300px
`
const ShadowImage = styled.img`
width: 100%;
height: auto;
`

const AnimateImg = styled.div`
  /* position: relative; */
`;
const index = () => {
  return (
    <div>
      <SectionWrapper p="10rem 0" id="about">
        <Container maxWidth="xl">
          <Grid container>
            <GridImage
              item
              xs={12}
              md={6}
              data-aos="fade-up"
              data-aos-duration="19000"
            >
              <AnimateImg>
                <MovingImg>
                  <ProblemImage
                    src="/images/probleming.png"
                  />
                </MovingImg>
                <ShadowImg>
                  <ShadowImage
                    src="/images/shadow.png"
                  />
                </ShadowImg>
              </AnimateImg>
            </GridImage>
            <Grid item xs={12} md={6}>
              <SectionSmallHeading
                fs="16px"
                color="#F7BE13"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="100"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                Why We Create Proof Of Poodle
              </SectionSmallHeading>
              <SectionHeading

                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="300"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                Problem
              </SectionHeading>
              <P
                maxWidth="80%"
                p="20px 0 0 0"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="500"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                A lot of projects rely on improper methods of adding liquidity
                to their tokens. Either the tokens don&apos;t really contribute
                to the liquidity pool or they&apos;re just too volatile to be
                traded. To have a large liquidity pool without the huge upfront
                costs forces many projects resort to upfront funding for their
                tokens with pre-sale rounds and the like, often resulting in
                major dumps. Simply put, strong LP attracts whales.
              </P>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;

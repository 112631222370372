import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  /* background-color: #0a2f42; */
  padding-top: ${(props) => (props.pt ? props.pt : "110px")};
  padding: ${(props) => (props.p ? props.p : "")};
  padding-bottom: ${(props) => (props.pb ? props.pb : "")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    min-height: unset;
    min-height: ${(props) => (props.mh900px ? props.mh900px : "")};
  }
  @media screen and (max-width: 650px) {
    min-height: ${(props) => (props.mh ? props.mh : "")};

    padding-top: ${(props) => (props.pt650px ? props.pt650px : "")};
    padding-bottom: ${(props) => (props.pb650px ? props.pb650px : "")};
  }
  @media screen and (max-width: 410px) {
    min-height: ${(props) => (props.mh410 ? props.mh410 : "")};
  }
`;
const P = styled.p`
  font-size: ${(props) => (props.fs ? props.fs : "16px")};
  font-family: "poppins-light";

  font-weight: ${(props) => (props.fw ? props.fw : "600")};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin-top: ${(props) => (props.mt ? props.mt : "20px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "20px")};
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};

  @media (max-width: 1250px) {
    font-size: ${(props) => (props.fs1250 ? props.fs1250 : "")};
  }
  @media (max-width: 899px) {
    line-height: 24px;
    max-width: ${(props) => (props.maxWidth899 ? props.maxWidth899 : "100%")};
    margin-left: ${(props) => (props.ml599 ? props.ml599 : "0px")};
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
  @media (max-height: 700px) {
    font-size: 14px;
  }
`;

const SectionHeading = styled.h2`
  color: #ffffff;
  font-family: "Hanson-Bold";
  font-size: ${(props) => (props.fs ? props.fs : "60px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  text-align: ${(props) => (props.align ? props.align: "left")};
  padding: ${(props) => (props.p ? props.p: "")};

  @media (max-width: 1260px) {
   
  }
  @media (max-width: 899px) {
    text-align: ${(props) => (props.align899 ? props.align899: "left")};
  }
  @media (max-width: 599px) {
    font-size: 35px;
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "poppins-Bold";
  color: #ffffff;
  font-size: ${(props) => (props.fs ? props.fs : "30px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "30px")};
  text-align: ${(props) => (props.tc ? props.tc : "")};

  @media (max-width: 899px) {
    
  }
  @media (max-width: 599px) {
    
  }
`;
const SectionSmallHeading = styled.h4`
  padding-top: 0;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "10px")};
  font-size: ${(props) => (props.fs ? props.fs : "21px")};
  text-align: ${(props) => (props.tc ? props.tc : "")};
  font-family: "poppins-light";
  color: ${(props) => (props.color ? props.color : "#fff")};;
  @media (max-width: 899px) {
    font-size: 20px;
  }
  @media (max-width: 599px) {
    font-size: 20px;
  }
`;
const Box = styled.div`
  text-align: ${(props) => (props.align ? props.align : "")};
  background: rgba(22, 46, 66, 0.2);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  padding: ${(props) => (props.padding ? props.padding : "30px")};

  /* max-width: ${(props) => (props.maxWidth ? props.maxWidth : "10%")}; */
  padding-left: ${(props) => (props.pl ? props.pl : "")};

  margin-top: ${(props) => (props.mt ? props.mt : "40px")};
  @media screen and (max-width: 1240px) {
    min-height: ${(props) => (props.minh ? props.minh : "")};
  }
  @media screen and (max-width: 899px) {
    padding-left: ${(props) => (props.pl899px ? props.pl899px : "10px")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.padding599 ? props.padding599 : "")};
    max-width: 100%;
    /* min-height: ${(props) => (props.mh ? props.mh : "")}; */
  }
  @media screen and (max-width: 387px) {
    min-height: ${(props) => (props.mh ? props.mh : "")};
  }
`;
export {
  SectionSmallHeading,
  SectionWrapper,
  P,
  SectionHeading,
  SectionSubHeading,
  Box,
};

import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { SectionHeading, SectionWrapper } from "../../../styles";

const Box = styled.div`
  text-align: ${(props) => (props.align ? props.align : "")};
  /* background: rgba(255, 255, 255, 0.3); */
  background-image: radial-gradient(
    rgba(83, 35, 103, 0.8),
    rgba(44, 34, 99, 0.8)
  );
  box-shadow: inset 1px 1px 13px 0px rgba(255, 255, 255, 0.5);
  text-align: -webkit-center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  padding: ${(props) => (props.padding ? props.padding : "30px")};
  /* max-width: ${(props) => (props.maxWidth ? props.maxWidth : "10%")}; */
  padding-left: ${(props) => (props.pl ? props.pl : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "40px")};
  max-width: 100%;

  @media screen and (max-width: 899px) {
    padding-left: ${(props) => (props.pl899px ? props.pl899px : "10px")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.padding599 ? props.padding599 : "")};
    max-width: 100%;
  }
`;
const Socials = styled.a`
  padding-left: 20px;
`;
const Social = styled.div`
  text-align: -webkit-left;
`;
const OtherGrid = styled(Grid)`
  align-self: center;
  @media screen and (max-width: 899px) {
    text-align-last: center;
  }
`;
const Footer = styled.div`
  text-align: center;
  padding: 70px 0 30px 0;
`;
const FooterImage = styled.img`
width: 100%;
height: auto;
max-width:220px
`
const SocialImage = styled.img`
width: 100%;
height: auto;
max-width: 33px
`

const index = () => {
  return (
    <div>
      <SectionWrapper pt="0px">
        <Container maxWidth="xl">
          <Box data-aos="zoom-in" data-aos-duration="19000">
            <Grid container>
              <Grid xs={12} md={4}>
                <FooterImage
                  src="/images/footerpoodle.png"
                />
              </Grid>

              <OtherGrid xs={12} md={8}>
                <SectionHeading
                  align899="center"
                  fs="45px"
                  align="left"
                  p="30px 0"
                >
                  Join Poodle Community
                </SectionHeading>
                <Social>
                  <Socials href="https:/t.me/poodle_avax" target="blank">
                    <SocialImage
                      src="/images/telegram.png"
                    />
                  </Socials>
                  <Socials href="https://twitter.com/poodle_avax" target="blank">
                    <SocialImage
                      src="/images/twitterforpoodle.png"
                    />
                  </Socials>
                  <Socials
                    href="1234567890"
                    target="blank"
                  >
                    <SocialImage
                      src="/images/medium.png"
                    />
                  </Socials>
                </Social>
              </OtherGrid>
            </Grid>
          </Box>
          <Footer
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="600"
            data-aos-offset="0"
            data-aos-duration="1000"
          >
            Copyright © 2022 PoodleCoin
          </Footer>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;

import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionHeading,
  SectionSmallHeading,
  SectionWrapper,
} from "../../../styles";

const GridImage = styled(Grid)`
  text-align-last: center;
`;
const AboutImage = styled.img`
width: 100%;
heigt: auto;
max-width: 560px
`

const index = () => {
  return (
    <div>
      <SectionWrapper p="5rem 0" id="solution">
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} md={6}>
              <SectionSmallHeading
                color="#F7BE13"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="100"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                What We Had Created
              </SectionSmallHeading>
              <SectionHeading
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="300"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                Solution
              </SectionHeading>
              <P
                maxWidth="80%"
                p="20px 0 0 0"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                This is where Proof of Poodle comes in. We&apos;re one of the only
                projects where our contract contributes to the liquidity pool
                via a stablecoin. Using our industry leading token contract, the
                funds collected through fair launches get added directly to the
                liquidity pool. Thus helping bring a gradual rise to the LP
                alongside the trading volume and opening up new avenues for
                larger investors to come in and fill up their bags.
              </P>
            </Grid>
            <GridImage
              item
              xs={12}
              md={6}
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-delay="600"
              data-aos-offset="0"
              data-aos-duration="1000"
            >
              <AboutImage
                src="/images/aboutpoodle.png"
              />
            </GridImage>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;

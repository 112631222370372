import './App.css';
import S1_Header from "./pages/MainPage/S1_Header";
import S2_About from "./pages/MainPage/S2_About";
import S21_Problem from "./pages/MainPage/S1-5_Problem";
import S3_Features from "./pages/MainPage/S3_Features";
import S4_Tokenomics from "./pages/MainPage/S4_Tokenomics";
import S5_Community from "./pages/MainPage/S5_Community";
import styled from "@emotion/styled";

const CompleteBackground = styled.div`
  background-image: url("/images/fullbg.png");
  background-repeat: no-repeat;
  -webkit-background-size: COVER;
  background-size: cover;
`;

function App() {
  return (
    <div>
      <S1_Header />
      <CompleteBackground>
        <S21_Problem />
        <S2_About />
        <S3_Features />
        <S4_Tokenomics />
        <S5_Community />
      </CompleteBackground>
    </div>
  );
}

export default App;
